import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Brush, Evaluator, SUBTRACTION, ADDITION } from "three-bvh-csg";
import Stats from "stats.js"; // stats.js'i npm ile kurduğunuzu varsayıyorum
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import Chart from "chart.js/auto";
import boxer from "./assets/gif/boxer.gif";
import catwoman from "./assets/gif/catwoman.gif";
import cyborg from "./assets/gif/cyborg.gif";
import discogirl from "./assets/gif/discogirl.gif";
import hero from "./assets/gif/hero.gif";
import muscleman from "./assets/gif/muscleman.gif";
import oldman from "./assets/gif/oldman.gif";
import { formatDistanceToNow } from "date-fns";

// Scene setup
function initScene(modelUrl, cubeYPosition, modelHolder) {
  // Initialize core Three.js components
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    15, // Field of view
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  camera.position.z = 3;
  // Add more lighting to enhance the scene
  const frontLight = new THREE.DirectionalLight(0xffffff, 1);
  frontLight.position.set(0, 0, 10);
  scene.add(frontLight);

  const backLight = new THREE.DirectionalLight(0xffffff, 1);
  backLight.position.set(0, 0, -10);
  scene.add(backLight);

  // Increase intensity of ambient light for better overall illumination
  const fillLight = new THREE.AmbientLight(0xffffff, 1);
  scene.add(fillLight);

  // Add rim lighting effect
  const rimLight = new THREE.SpotLight(0xffffff, 1);
  rimLight.position.set(-10, 10, -10);
  scene.add(rimLight);

  // Set up renderer
  const container = modelHolder;
  const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  renderer.setSize(container.clientWidth, container.clientHeight);
  container.appendChild(renderer.domElement);

  // Configure camera controls
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.05;
  controls.minDistance = 30;
  controls.maxDistance = 80;
  camera.position.z = 50;

  //   controls.enablePan = false;
  //   controls.enableZoom = false;

  // Add scene lighting
  const directionalLight = new THREE.DirectionalLight(0xffffff, 4);
  directionalLight.position.set(5, 20, 15);
  scene.add(directionalLight);

  const ambientLight = new THREE.AmbientLight(0x404040, 2);
  scene.add(ambientLight);

  const pointLight = new THREE.PointLight(0xffffff, 4, 100);
  pointLight.position.set(-20, 10, -10);
  scene.add(pointLight);

  // Material for visualization
  const sphereMaterial = new THREE.MeshStandardMaterial({
    color: 0xff0000,
    transparent: true,
    opacity: 0.5,
  });

  // Wave effect setup
  let waveStartTime = performance.now();
  let initialVertices = null;

  // Apply wave deformation to geometry vertices
  function applyWaveEffect(geometry) {
    const positionAttribute = geometry.attributes.position;
    const vertices = positionAttribute.array;
    const time = (performance.now() - waveStartTime) * 0.02;

    if (!initialVertices) {
      initialVertices = vertices.slice();
    }

    // Calculate geometry bounds
    let minY = Infinity;
    let maxY = -Infinity;
    for (let i = 1; i < initialVertices.length; i += 3) {
      const y = initialVertices[i];
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y);
    }

    // Apply wave deformation
    for (let i = 0; i < vertices.length; i += 3) {
      const x = initialVertices[i];
      const y = initialVertices[i + 1];
      const z = initialVertices[i + 2];

      const waveStrength = THREE.MathUtils.clamp(
        (y - minY) / (maxY - minY),
        0.0001,
        1
      );

      const waveEffect =
        (Math.sin(x * 3 + time) * 0.2 + Math.cos(z * 3 + time) * 0.2) *
        waveStrength;

      vertices[i] = x;
      vertices[i + 1] = y + waveEffect;
      vertices[i + 2] = z;
    }

    positionAttribute.needsUpdate = true;
  }

  // Performance monitoring
  const stats = new Stats();
  stats.showPanel(0);
  // document.body.appendChild(stats.dom);

  // Animation setup
  let mixer;
  const clock = new THREE.Clock();

  // Load and process 3D model
  const loader = new GLTFLoader();
  loader.load(
    modelUrl,
    function (gltf) {
      const model = gltf.scene;
      scene.add(model);
      model.scale.set(2, 2, 2);
      model.position.set(0, -5, 0);

      // Get model components
      const sphereS = model.getObjectByName("SphereS");
      const cubeS = model.getObjectByName("CubeS");
      const sphere = model.getObjectByName("Sphere");
      const cube = model.getObjectByName("Cube");
      const rigga = model.getObjectByName("rigga");
      const popo = rigga.getObjectByName("popo");
      const cylinder = model.getObjectByName("Cylinder");

      // Position adjustments
      cubeS.position.y = cubeYPosition;

      // Create CSG brushes and perform subtraction
      const cubeBrush = new Brush(cubeS.geometry, sphere.material);
      const sphereBrush = new Brush(sphereS.geometry, sphere.material);
      cubeBrush.position.copy(cubeS.position);
      cubeBrush.updateMatrixWorld();

      const evaluator = new Evaluator();
      const subMesh = evaluator.evaluate(sphereBrush, cubeBrush, SUBTRACTION);

      // Scale and position result
      const scalle = 0.035;
      subMesh.scale.set(scalle, scalle, scalle);
      subMesh.position.copy(sphere.position);
      subMesh.rotation.copy(sphere.rotation);
      popo.add(subMesh);

      // Hide original meshes
      sphereS.visible = false;
      cubeS.visible = false;
      sphere.visible = false;
      //   cube.visible = false;
      // Set up rotation animation
      var isRotating = true;
      const rotationSpeed = 0.0075;

      // Add click listener to stop rotation
      modelHolder.addEventListener("click", () => {
        isRotating = false;
      });
      // Animation loop
      function animate() {
        stats.begin();

        if (mixer) {
          mixer.update(clock.getDelta());
        }

        applyWaveEffect(subMesh.geometry);
        controls.update();
        renderer.render(scene, camera);
        stats.end();
        requestAnimationFrame(animate);

        // Add rotation to animation loop

        if (isRotating) {
          model.rotation.y += rotationSpeed;
        }
      }

      animate();

      // Set up animations if present
      if (gltf.animations?.length > 0) {
        mixer = new THREE.AnimationMixer(model);
        gltf.animations.forEach((clip) => mixer.clipAction(clip).play());
      }
    },
    (progress) => {
      if (progress.loaded > 85)
        document.querySelector(".model-loader").style.display = "none";
    },
    (error) => console.error("Model loading error:", error)
  );

  // Handle window resizing
  window.addEventListener("resize", () => {
    const width = container.clientWidth;
    const height = container.clientHeight;
    renderer.setSize(width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  });
}

window.initScene = initScene;

// Update the popup HTML structure to include both model and data
function createPopupContent() {
  const popupContent = document.querySelector(".popup-content");
  popupContent.innerHTML = `
    <span class="close">&times;</span>
    <div class="popup-inner">
      <div class="video-container ratio ratio-9x16">
       <video id="character-video" class="ratio ratio-9x16" controls>
              <source src="#" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
      </div>
      <div class="data-section">
        <div class="chart-container">
          <canvas id="pie-chart"></canvas>
        </div>
        <div class="holders-container">
          <div class="holders-header">
            <h3>Holders List</h3>
            <span id="last-update"></span>
          </div>
          <div class="search-container">
            <input 
              type="text" 
              id="holders-search" 
              placeholder="Search wallet address..."
              autocomplete="off"
            >
            <span class="search-icon">🔍</span>
          </div>
          <div class="holders-list"></div>
        </div>
      </div>
    </div>
  `;
}

// Function to fetch data from both endpoints
async function fetchCharacterData(characterId) {
  try {
    // Get mockup data
    const selectedModel = window.models.find(
      (model) => model.id == parseInt(characterId)
    );

    const pointsDB = window.points.find(
      (point) => point.model_id === parseInt(characterId)
    );

    const points = {
      instagram: pointsDB.instagram || 0,
      tiktok: pointsDB.tiktok || 0,
      x: pointsDB.x || 0,
      telegram: pointsDB.telegram || 0,
      holders: pointsDB.holders || 0,
      remaining:
        selectedModel.points_required -
        (pointsDB.instagram || 0) -
        (pointsDB.tiktok || 0) -
        (pointsDB.x || 0) -
        (pointsDB.telegram || 0) -
        (pointsDB.holders || 0),
    };
    const holdersDB = await getHolders(characterId);
    const holders = {
      last_update: holdersDB[0].updated_at,
      holders: holdersDB.map((holder) => holder.wallet_address),
    };
    if (!points || !holders) {
      throw new Error("Character data not found");
    }

    return { points, holders };
  } catch (error) {
    console.error("Error fetching character data:", error);
    return null;
  }
}

// Updated pie chart initialization
function initPieChart(points) {
  const ctx = document.getElementById("pie-chart").getContext("2d");

  // Calculate total points for percentage
  const total = Object.values(points).reduce((sum, value) => sum + value, 0);

  new Chart(ctx, {
    type: "pie",
    data: {
      labels: Object.keys(points).map(
        (key) => key.charAt(0).toUpperCase() + key.slice(1)
      ),
      datasets: [
        {
          data: Object.values(points),
          backgroundColor: [
            "#E1306C", // instagram
            "#000000", // tiktok
            "#1DA1F2", // x
            "#0088cc", // telegram
            "#ffa500", // holders
            "#808080", // remaining
          ],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              const label = context.label || "";
              const value = context.raw || 0;
              const percentage = ((value / total) * 100).toFixed(1);
              return `${label}: ${value.toLocaleString()} drops (${percentage}%)`;
            },
          },
        },
        legend: {
          position: "bottom",
          labels: {
            color: "#fff",
            padding: 20,
            font: {
              size: 14,
            },
            generateLabels: function (chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map((label, i) => {
                  const value = data.datasets[0].data[i];
                  const percentage = ((value / total) * 100).toFixed(1);
                  return {
                    text: `${label}: ${value.toLocaleString()} (${percentage}%)`,
                    fillStyle: data.datasets[0].backgroundColor[i],
                    strokeStyle: data.datasets[0].backgroundColor[i],
                    lineWidth: 0,
                    hidden: false,
                    fontColor: "#fff",
                    index: i,
                  };
                });
              }
              return [];
            },
          },
        },
      },
    },
  });
}

// Function to display holders list
function displayHolders(holdersData) {
  const holdersContainer = document.querySelector(".holders-list");
  const lastUpdateEl = document.getElementById("last-update");
  const searchInput = document.getElementById("holders-search");

  // Update last update time
  const lastUpdate = new Date(holdersData.last_update);
  lastUpdateEl.textContent = `Last updated ${formatDistanceToNow(
    lastUpdate
  )} ago`;

  // Function to render holders list
  function renderHolders(holders, searchTerm = "") {
    const filteredHolders = holders.filter((holder) =>
      holder.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredHolders.length === 0) {
      holdersContainer.innerHTML = `
        <div class="no-results">
          No wallets found matching "${searchTerm}"
        </div>
      `;
      return;
    }

    holdersContainer.innerHTML = filteredHolders
      .map((holder, index) => {
        const originalIndex = holdersData.holders.indexOf(holder);
        return `
          <div class="holder-item ${searchTerm && "highlight"}">
            <span class="holder-rank">#${originalIndex + 1}</span>
            <span class="holder-address">${highlightMatch(
              holder,
              searchTerm
            )}</span>
          </div>
        `;
      })
      .join("");
  }

  // Function to highlight matching text
  function highlightMatch(text, searchTerm) {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.replace(regex, '<span class="highlight">$1</span>');
  }

  // Initial render
  renderHolders(holdersData.holders);

  // Add search functionality
  let searchTimeout;
  searchInput.addEventListener("input", (e) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      renderHolders(holdersData.holders, e.target.value.trim());
    }, 300); // Debounce search for better performance
  });
}

// Update the click handler to properly initialize both model and data
document.addEventListener("DOMContentLoaded", () => {});

// Function to initialize the model viewer
function initModelViewer(characterId) {
  const container = document.getElementById("model-viewer");
  container.innerHTML = ""; // Clear previous content

  // Assuming you have a function to initialize your three.js scene
  initScene(characterId, container);
}

// Function to create raining GIFs
function initRainingGifs() {
  const gifPaths = [
    catwoman,
    boxer,
    cyborg,
    discogirl,
    hero,
    muscleman,
    oldman,
  ];
  const MAX_GIFS = 12;
  const activeGifs = [];

  let currentGifIndex = Math.floor(Math.random() * gifPaths.length);

  const COLUMNS = 12;
  const columnWidth = window.innerWidth / COLUMNS;

  function createStar(x, y, angle, distance) {
    const star = document.createElement("div");
    star.style.position = "fixed";
    star.style.left = x + "px";
    star.style.top = y + "px";
    star.style.width = "8px";
    star.style.height = "8px";
    star.style.backgroundColor = "#FFD700";
    star.style.borderRadius = "50%";
    star.style.transform = "translate(-50%, -50%)";
    star.style.zIndex = "999";
    document.body.appendChild(star);

    let opacity = 1;
    let currentDistance = 0;

    function animate() {
      currentDistance += 5;
      opacity -= 0.03;

      const newX = x + Math.cos(angle) * currentDistance;
      const newY = y + Math.sin(angle) * currentDistance;

      star.style.left = newX + "px";
      star.style.top = newY + "px";
      star.style.opacity = opacity;

      if (opacity > 0) {
        requestAnimationFrame(animate);
      } else {
        star.remove();
      }
    }

    animate();
  }

  function createExplosion(gif) {
    const rect = gif.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    // Create water droplet particles
    for (let i = 0; i < 30; i++) {
      const droplet = document.createElement("div");
      droplet.style.position = "fixed";
      droplet.style.left = centerX + "px";
      droplet.style.top = centerY + "px";
      droplet.style.width = "4px";
      droplet.style.height = "4px";
      droplet.style.backgroundColor = "#87CEEB";
      droplet.style.borderRadius = "50%";
      droplet.style.transform = "translate(-50%, -50%)";
      droplet.style.zIndex = "998";
      document.body.appendChild(droplet);

      // Random angle and speed for each droplet
      const angle = Math.random() * Math.PI * 2;
      const speed = 2 + Math.random() * 3;
      const maxDistance = 50 + Math.random() * 50;
      let distance = 0;
      let opacity = 1;

      function animateDroplet() {
        distance += speed;
        opacity = 1 - distance / maxDistance;

        const newX = centerX + Math.cos(angle) * distance;
        const newY = centerY + Math.sin(angle) * distance * 1.5; // Elongated vertically

        droplet.style.left = newX + "px";
        droplet.style.top = newY + "px";
        droplet.style.opacity = opacity;

        if (opacity > 0) {
          requestAnimationFrame(animateDroplet);
        } else {
          droplet.remove();
        }
      }

      animateDroplet();
    }

    // Add ripple effect
    const ripple = document.createElement("div");
    ripple.style.position = "fixed";
    ripple.style.left = rect.left + "px";
    ripple.style.top = rect.top + "px";
    ripple.style.width = rect.width + "px";
    ripple.style.height = rect.height + "px";
    ripple.style.border = "2px solid #87CEEB";
    ripple.style.borderRadius = "50%";
    ripple.style.opacity = "0.8";
    ripple.style.transition = "all 0.5s ease-out";
    ripple.style.zIndex = "997";
    document.body.appendChild(ripple);

    requestAnimationFrame(() => {
      ripple.style.transform = "scale(2.5)";
      ripple.style.opacity = "0";
      setTimeout(() => ripple.remove(), 500);
    });
  }

  function removeAllGifs() {
    activeGifs.forEach((gif) => {
      gif.style.transition = "transform 0.3s ease-out, opacity 0.3s ease-out";
      gif.style.transform = "scale(1.5)";
      gif.style.opacity = "0";

      setTimeout(() => {
        createExplosion(gif);
        gif.remove();
      }, 10);
    });
    activeGifs.length = 0;

    setTimeout(() => {
      let newGifIndex = Math.floor(Math.random() * gifPaths.length);
      while (newGifIndex === currentGifIndex) {
        newGifIndex = Math.floor(Math.random() * gifPaths.length);
      }
      currentGifIndex = newGifIndex;
      createGif();
    }, 10);
  }

  function createGif() {
    if (activeGifs.length >= MAX_GIFS) return;

    const usedColumns = activeGifs.map((gif) => parseInt(gif.dataset.column));
    let column;
    do {
      column = Math.floor(Math.random() * COLUMNS);
    } while (usedColumns.includes(column));

    const gif = document.createElement("img");
    gif.src = gifPaths[currentGifIndex];
    gif.style.position = "fixed";
    gif.style.left = column * columnWidth + 25 + "px";
    gif.style.top = "-50px";
    gif.style.width = "72px";
    gif.style.height = "72px";
    gif.style.zIndex = "1000";
    gif.style.cursor = "pointer";
    gif.style.transition = "transform 0.2s ease";
    gif.dataset.column = column;

    gif.addEventListener("mouseover", () => {
      gif.style.transform = "scale(1.1)";
    });

    gif.addEventListener("mouseout", () => {
      gif.style.transform = "scale(1)";
    });

    document.body.appendChild(gif);
    activeGifs.push(gif);

    gif.addEventListener("click", removeAllGifs);

    let posY = -50;
    let wobble = 0;
    const speed = 2.5;

    function fall() {
      if (!document.body.contains(gif)) return;

      posY += speed;
      wobble = Math.sin(posY * 0.03) * 15;
      gif.style.top = posY + "px";
      gif.style.left = column * columnWidth + 25 + wobble + "px";

      if (posY > window.innerHeight) {
        gif.style.transition = "opacity 0.3s ease-out";
        gif.style.opacity = "0";
        setTimeout(() => {
          gif.remove();
          activeGifs.splice(activeGifs.indexOf(gif), 1);
          setTimeout(createGif, 1000);
        }, 300);
      } else {
        requestAnimationFrame(fall);
      }
    }

    fall();
  }

  for (let i = 0; i < 3; i++) {
    setTimeout(() => createGif(), i * 500);
  }

  setInterval(() => {
    if (Math.random() > 0.5 && activeGifs.length < MAX_GIFS) {
      createGif();
    }
  }, 1000);
}

function startSlidingGifs() {
  const MAX_SLIDING_GIFS = 8;
  const MIN_INTERVAL = 500; // Minimum .3 second between gifs

  const activeSlidingGifs = [];
  const gifUrls = [catwoman, boxer, cyborg, discogirl, hero, muscleman, oldman];

  function createSlidingGif() {
    const gif = document.createElement("img");
    gif.src = gifUrls[Math.floor(Math.random() * gifUrls.length)];
    gif.classList.add("sliding-gif");
    gif.style.position = "fixed";
    gif.style.zIndex = "1000";
    gif.style.width = "50px";
    gif.style.height = "50px";
    gif.style.transition = "transform 0.2s ease-out";
    gif.style.bottom = "0px";
    gif.style.right = "-50px";

    gif.addEventListener("mouseover", () => {
      gif.style.transform = "scale(1.2)";
    });

    gif.addEventListener("mouseout", () => {
      gif.style.transform = "scale(1)";
    });

    document.body.appendChild(gif);
    activeSlidingGifs.push(gif);

    gif.addEventListener("click", () => {
      function createExplosion(gif) {
        const rect = gif.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;

        // Create water droplet particles
        for (let i = 0; i < 30; i++) {
          const droplet = document.createElement("div");
          droplet.style.position = "fixed";
          droplet.style.left = centerX + "px";
          droplet.style.top = centerY + "px";
          droplet.style.width = "4px";
          droplet.style.height = "4px";
          droplet.style.backgroundColor = "#87CEEB";
          droplet.style.borderRadius = "50%";
          droplet.style.transform = "translate(-50%, -50%)";
          droplet.style.zIndex = "998";
          document.body.appendChild(droplet);

          // Random angle and speed for each droplet
          const angle = Math.random() * Math.PI * 2;
          const speed = 2 + Math.random() * 3;
          const maxDistance = 50 + Math.random() * 50;
          let distance = 0;
          let opacity = 1;

          function animateDroplet() {
            distance += speed;
            opacity = 1 - distance / maxDistance;

            const newX = centerX + Math.cos(angle) * distance;
            const newY = centerY + Math.sin(angle) * distance * 1.5; // Elongated vertically

            droplet.style.left = newX + "px";
            droplet.style.top = newY + "px";
            droplet.style.opacity = opacity;

            if (opacity > 0) {
              requestAnimationFrame(animateDroplet);
            } else {
              droplet.remove();
            }
          }

          animateDroplet();
        }

        // Add ripple effect
        const ripple = document.createElement("div");
        ripple.style.position = "fixed";
        ripple.style.left = rect.left + "px";
        ripple.style.top = rect.top + "px";
        ripple.style.width = rect.width + "px";
        ripple.style.height = rect.height + "px";
        ripple.style.border = "2px solid #87CEEB";
        ripple.style.borderRadius = "50%";
        ripple.style.opacity = "0.8";
        ripple.style.transition = "all 0.5s ease-out";
        ripple.style.zIndex = "997";
        document.body.appendChild(ripple);

        requestAnimationFrame(() => {
          ripple.style.transform = "scale(2.5)";
          ripple.style.opacity = "0";
          setTimeout(() => ripple.remove(), 500);
        });
      }
      // Create explosion effect
      createExplosion(gif);

      // Remove the gif
      gif.remove();
      activeSlidingGifs.splice(activeSlidingGifs.indexOf(gif), 1);
      setTimeout(createSlidingGif, 2000);
    });

    let posX = window.innerWidth;
    const speed = 1.5; // Reduced from 3 to 1.5 for slower movement

    function slide() {
      if (!document.body.contains(gif)) return;

      posX -= speed;
      gif.style.right = window.innerWidth - posX + "px";
      gif.style.bottom = "0px";

      if (posX < -50) {
        gif.style.transition = "opacity 0.3s ease-out";
        gif.style.opacity = "0";
        setTimeout(() => {
          gif.remove();
          activeSlidingGifs.splice(activeSlidingGifs.indexOf(gif), 1);
          setTimeout(createSlidingGif, 1000);
        }, 300);
      } else {
        requestAnimationFrame(slide);
      }
    }

    slide();
  }

  // Initialize with a few gifs with longer intervals
  for (let i = 0; i < 3; i++) {
    setTimeout(() => createSlidingGif(), i * 2000); // 2 seconds between initial gifs
  }

  // Randomly add new gifs with minimum interval
  setInterval(() => {
    if (Math.random() > 0.7 && activeSlidingGifs.length < MAX_SLIDING_GIFS) {
      setTimeout(createSlidingGif, MIN_INTERVAL);
    }
  }, 2000); // Check every 2 seconds
}

async function fetchFromCore(action, params = {}) {
  const url = new URL("/core.php", window.location.origin);
  url.searchParams.append("action", action);

  // Append additional parameters to the URL
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching from core.php:", error);
    return { error: error.message };
  }
}

async function getModels(active = null) {
  const params = {};
  if (active !== null) {
    params.active = active ? 1 : 0;
  }
  return await fetchFromCore("get_models", params);
}

async function getPoints(modelId = null) {
  const params = modelId ? { model_id: modelId } : {};
  return await fetchFromCore("get_points", params);
}

async function getMintAddress() {
  return await fetchFromCore("get_mint_address");
}

async function getHolders(modelId) {
  if (!modelId && modelId !== 0) {
    throw new Error("model_id is required for getHolders");
  }
  return await fetchFromCore("get_holders", { model_id: modelId });
}

async function getSettings() {
  return await fetchFromCore("get_settings");
}

window.apiCalls = {
  getModels,
  getPoints,
  getMintAddress,
  getHolders,
  getSettings,
};

window.document.addEventListener("DOMContentLoaded", async () => {
  // initRainingGifs();
  startSlidingGifs();

  const promises = [];
  promises.push(window.apiCalls.getModels());
  promises.push(window.apiCalls.getPoints());
  window.apiCalls.getSettings().then((settings) => {
    window.settings = settings;
    if (settings.buy_link) {
      document.getElementById("buy-link-hero").href = settings.buy_link;
      document.getElementById("buy-link-menu").href = settings.buy_link;
      document.getElementById("buy-link-menu").target = "_blank";
      document.getElementById("buy-link-hero").target = "_blank";
    }
    if (settings.mint_address) {
      document.getElementById("mint-address").textContent =
        settings.mint_address;
    }
  });

  Promise.all(promises).then(([models, points]) => {
    window.models = models;
    window.points = [];
    points.forEach((point) => {
      if (window.points.find((model) => model.model_id == point.model_id)) {
        window.points.find((model) => model.model_id == point.model_id)[
          point.source
        ] = point.point;
      } else {
        window.points.push({
          model_id: point.model_id,
          [point.source]: point.point,
        });
      }
    });

    const activeModel = models.find((model) => model.active === 1);
    const totalPointsForActiveModel = points.reduce(
      (acc, point) => acc + point.point,
      0
    );

    initScene(
      activeModel.model_url,
      (totalPointsForActiveModel / activeModel.points_required) * 6,
      document.getElementById("model-container")
    );
    // Calculate the percentage of the character to fill
    const fillPercentage = Math.min(
      (totalPointsForActiveModel / activeModel.points_required) * 100,
      100
    );

    // Update the progress bar width and text based on the percentage

    const progressBar = document.getElementById("progress-bar");
    const progressText = document.getElementById("progress-text");

    progressBar.style.width = fillPercentage + "%";
    progressText.textContent =
      fillPercentage.toFixed(1) + "% of our current character filled!";

    // If the character is fully filled, display a message
    if (fillPercentage >= 100) {
      progressText.textContent =
        "Character filled! Unlocking the next character...";
      // You can add logic here to change to the next character
    }
    // Populate character cards based on models
    const characterCardsContainer = document.querySelector(".character-grid");
    characterCardsContainer.innerHTML = models
      .map(
        (model) => `
      <div class="character-card" data-id="${model.id}">
        ${
          model.active !== 1
            ? `
          <div class="overlay">
            <div class="question-mark">?</div>
          </div>
        `
            : ""
        }
        <img src="${model.thumbnail_url}" alt="${model.name}" />
        <div class="card-caption">#${model.id} ${model.name}</div>
      </div>
    `
      )
      .join("");
    // Add coming soon card
    characterCardsContainer.innerHTML += `
      <div class="character-card coming-soon">
        <div class="overlay">
          <div class="question-mark">?</div>
        </div>
        <img src="/assets/images/logo.png" alt="Coming Soon" />
        <div class="card-caption">#${models.length + 1} Character</div>
      </div>
    `;

    const characterCards = document.querySelectorAll(".character-card");
    const popup = document.getElementById("character-popup");

    characterCards.forEach((card) => {
      card.addEventListener("click", async () => {
        const activeModel = window.models.find((model) => model.active === 1);
        createPopupContent();
        const characterId = card.getAttribute("data-id");
        popup.style.display = "flex";
        requestAnimationFrame(() => {
          popup.classList.add("active");
        });

        // Set video source
        const video = document.getElementById("character-video");
        video.src = activeModel.video_url;
        video.load(); // Reload video with new source

        // Fetch and display data
        const data = await fetchCharacterData(characterId);
        if (data) {
          initPieChart(data.points);
          displayHolders(data.holders);
        }

        // Add event listener to the newly created close button
        const closeBtn = document.querySelector(".popup-content .close");
        closeBtn?.addEventListener("click", (e) => {
          e.stopPropagation();
          closePopup();
        });
      });
    });

    function closePopup() {
      popup.classList.remove("active");
      // Stop video playback when closing popup
      const video = document.getElementById("character-video");
      video.pause();
      video.currentTime = 0;
      // Wait for transition to complete before hiding
      setTimeout(() => {
        popup.style.display = "none";
      }, 300);
    }

    // Handle clicking outside the popup
    window.addEventListener("click", (event) => {
      if (event.target === popup) {
        closePopup();
      }
    });

    // Create the points info popup HTML
    const pointsInfoPopup = document.createElement("div");
    pointsInfoPopup.className = "points-info-popup";
    pointsInfoPopup.innerHTML = `
    <div class="points-info-content">
      <span class="close">&times;</span>
      <h3>Drops System</h3>
      <div class="points-list">
        <div class="points-item">
          <div class="label">
            <span>Social Media Followers</span>
          </div>
          <div class="value">1 drop each</div>
        </div>
        <div class="points-item">
          <div class="label">
            <span>Telegram Members</span>
          </div>
          <div class="value">50 drops each</div>
        </div>
        <div class="points-item">
          <div class="label">
            <span>Coin Holders</span>
          </div>
          <div class="value">250 drops each</div>
        </div>
      </div>
      <div class="total-points">
        Total Drops Needed: <span id="full-points-value">Loading...</span>
      </div>
    </div>
  `;
    document.body.appendChild(pointsInfoPopup);

    // Add click handler for info icon
    const infoIcon = document.querySelector(".info-icon");
    infoIcon?.addEventListener("click", (e) => {
      e.stopPropagation();
      const fullPoints = infoIcon.getAttribute("data-full-points");
      document.getElementById("full-points-value").textContent =
        parseInt(fullPoints).toLocaleString();
      pointsInfoPopup.style.display = "flex";
    });

    // Add close handlers
    const closePointsInfo = () => {
      pointsInfoPopup.style.display = "none";
    };

    pointsInfoPopup.querySelector(".close")?.addEventListener("click", (e) => {
      e.stopPropagation();
      closePointsInfo();
    });

    pointsInfoPopup.addEventListener("click", (e) => {
      if (e.target === pointsInfoPopup) {
        closePointsInfo();
      }
    });
  });

  AOS.init();
  // Load giphy gallery when tokenomics section comes into view (only once)
  let giphyGalleryLoaded = false;
  const tokenomicsSection = document.getElementById("tokenomics");
  if (tokenomicsSection) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !giphyGalleryLoaded) {
          loadGiphyGallery();
          giphyGalleryLoaded = true;
          observer.unobserve(entry.target); // Stop observing after loading
        }
      });
    });
    observer.observe(tokenomicsSection);
  }
});

function loadGiphyGallery() {
  const giphyIds = [
    "7jg81AnaXaTTfPCoX8",
    "pqreE9q6DKGKMyI5P3",
    "fll7tB9nyeGUbEWxfI",
    "J655xp2B8VMuEzs1Yz",
    "t242jiTNqVr9U3kOIV",
    "rZJLtqqVBELbWvFu40",
    "hZYTtNMsSH0Mcp4DWM",
    "ibdtB11kTske0luPBY",
    // Add more IDs here
  ];

  const galleryGrid = document.querySelector(".gallery-grid");

  giphyIds.forEach((id) => {
    const container = document.createElement("div");
    container.className = "giphy-embed-container";

    container.innerHTML = `
      <iframe
        src="https://giphy.com/embed/${id}"
        frameBorder="0"
        class="giphy-embed"
        allowFullScreen>
      </iframe>
    `;

    galleryGrid.appendChild(container);
  });
}
